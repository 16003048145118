<template id="modal-template">
  <div>
    <button v-on:click="toggleModal()" v-bind:class="{active: isActive1}" >Pillars</button>

    <div v-if="showModal" class="modal">
      <div class="center-content">
        <div
          class="center-text-center animate"
          style="text-align: center; display: block"
        >
          <h1 class="section-title" style="margin-top: -6vw; margin-bottom: 4.5vw;">
            The Dell.com Pillars
          </h1>
        </div>
        <div class="row-content animate sm">
  
          <div class="col-3" >
            <a href="/#/Inspire" @click="scroll">
            <div class="info-box sm">
              <p class="blue" style="margin-bottom: 10px; font-weight:bold">Inspire</p>
              <p>
                Offering an enjoyable experience that shows users what’s
                possible.
              </p>

            </div>          </a>
          </div>

          <div class="col-3">
             <a href="/#/Learn" @click="scroll">
            <div class="info-box sm">
              <p class="blue" style="margin-bottom: 10px;  font-weight:bold">Learn</p>
              <p>
                Educating users about innovative products and services,
                technology skill sets, and the Dell brand.
              </p>

            </div>
             </a>
          </div>
          <div class="col-3">
             <a href="/#/Buy" @click="scroll">
            <div class="info-box sm">
              <p class="blue" style="margin-bottom: 10px;  font-weight:bold">Buy</p>
              <p>
                Creating a seamless process for customers to discover, purchase,
                and pay for Dell products and services.
              </p>

            </div>
             </a> 
          </div> 
          <div class="col-3">
             <a href="/#/Use" @click="scroll">
            <div class="info-box sm">
              <p class="blue" style="margin-bottom: 10px;  font-weight:bold">Use</p>
              <p>
                Giving customers the tools to get the most out of their
                products, while providing hands-on support and guidance when
                needed.
              </p>
     
            </div>
             </a>
          </div>
        </div>
      </div>
      <a class="close-modal focus:outline-none" v-on:click="toggleModal()"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PillarsModal",
  data() {
    return {
      showModal: false,
      isActive1: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      this.isActive1 = !this.isActive1;
    },
     scroll(event) {
      window.scrollTo(0,0);
    }
  },
};
</script>

<style scoped>
button {
  position: fixed;
  bottom: 5%;
  left: 5%;
  background: transparent;
  z-index: 2;
  box-shadow: none;
    width: 7vw;
    height: 6vh;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
  display: block;
      font-size: .8vw;
}
.active{
  display: none;
}
a{
      text-decoration: none;
}
.modal {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding: 20px;
  right: 0;
  position: fixed;
  background: url("../assets/PillarsCTA_BG.png");
  z-index: 11111111;
  animation: fadeIn 2s;
}
.close-modal {
  position: fixed;
  bottom: 5%;
  left: 4.5%;
  width: 111px;
  height: 44px;
  background-size: 115px 49px !important;
  background: url("../assets/close-modal.png");
}
.close-modal span {
  color: white;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>