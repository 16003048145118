<template>
  <div class="left-cover"></div>
  <div id="topLeft">
    <a href="/">
      <img src="../assets/logo-home.svg" alt="" />
    </a>
  </div>
  <div id="topRight">
    <a href="/">
      <img src="../assets/home.png" alt="" />
    </a>
  </div>
  <div class="left-nav pillars">
    <ul>
      <li><a href="/#/Inspire" @click="scroll">Inspire</a></li>
      <li><a href="/#/Learn" @click="scroll">Learn</a></li>
      <li>
        <a href="/#/Buy" @click="scroll" class="section-anchor active">Buy</a>
      </li>
      <a href="#" data-section="nicole" class="section-anchor" id="nicole-link"
        ><img src="../assets/Nicole.png"
      /></a>
      <a href="#" data-section="art" class="section-anchor" id="art-link">
        <img src="../assets/ART.png"
      /></a>
      <a href="#" data-section="anna" class="section-anchor" id="anna-link"
        ><img src="../assets/anna.png"
      /></a>
      <li><a href="/#/Use" @click="scroll">Use</a></li>
    </ul>
  </div>
  <div class="bottomLeft">
    <a href="/#/Journey">User Persona</a>
  </div>
  <div class="right-nav controlArrow" id="controlArrow">
    <button class="previus scroll-up" id="btn-back">
      <img src="../assets/arrow-left.png" />
    </button>
    <button class="next" id="btn-next">
      <img src="../assets/arrow-right.png" />
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("content-show");
        } else {
          entry.target.classList.add("content-above");
          entry.target.classList.remove("content-show");
        }
        //prevRatio = entry.intersectionRatio;
      });
    },
    addObserver(el) {
      let observer;
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: [0.1, 0],
      };
      observer = new IntersectionObserver(this.handleIntersect, options);
      observer.observe(el);
    },
    scroll(event) {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    let allElements = document.getElementsByClassName("section");
    [...allElements].forEach((el) => {
      this.addObserver(el);
    });

    document.querySelectorAll(".right-nav button").forEach((btn) => {
      btn.addEventListener("click", function () {
        let pageHeight = window.innerHeight;
        if (btn.className.split(" ").includes("scroll-up")) {
          pageHeight *= -1;
        }
        window.scrollBy(0, pageHeight);
      });
    });
    document.querySelectorAll(".section-anchor").forEach((btn) => {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        let t_s_id = this.dataset.section;
        let target_section = document.getElementById(t_s_id);
        target_section.scrollIntoView({ block: "end", inline: "nearest" });
      });
    });
  },
};
</script>
<style scoped lang="scss">
.left-cover {
  position: fixed;
  background-image: url("../assets/left-cover.png");
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  width: 500px;
  left: -194px;
}
.left-nav {
  position: fixed;
  left: 5%;
  top: 40%;
  transform: translate(-40%, 0%);
  z-index: 11;
  a {
    display: block;
    margin-bottom: 10px;
  }
  img {
    width: 60px;
  }
  ul {
    margin-left: 0;
  }
  li {
    list-style: none;
    text-align: left;
    margin-bottom: 20px;
    a {
      color: white;
      font-size: 0.9vw;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.persona-nav {
  margin-left: 35px;
  margin-top: -80px;
  margin-bottom: 60px;
  span {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    color: white;
    margin: 10px 0 15px;
    text-align: center;
  }
  img {
    z-index: 2;
  }
  &_secondary {
    transform: translate(15px, -120px);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-delay: 0s;
    img {
      width: 60px;
    }
  }
  &:hover {
    .first {
      transform: translate(107px, -110px);
      z-index: 1;
    }
    .second {
      transform: translate(80px, -203px);
      z-index: 1;
    }
  }
}
.right-nav {
  position: fixed;
  z-index: 111;
  right: 5%;
  bottom: 5%;
  img {
    width: 1.5vw;
  }
  button {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
#topLeft {
  position: relative;
}
#topLeft img {
  top: 5%;
  width: 356px;
  position: fixed;
  z-index: 2;
}
#topRight {
  position: relative;
}
#topRight img {
  top: 5%;
  right: 5%;
  position: fixed;
  z-index: 2;
}
.bottomLeft a {
  position: fixed;
  bottom: 5%;
  left: 5%;
  background: transparent;
  z-index: 2;
  box-shadow: none;
  width: 7vw;
  height: 4vh;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
  display: block;
  font-size: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 5px 10px;
}
</style>
