<template>
  <section
    class="full-panel"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/journey.jpg') + ')',
    }"
  >
    <div id="topLeft">
      <a href="/">
        <img src="../../assets/logo.svg" alt="" />
      </a>
    </div>
    <div id="topRight">
      <a href="/">
        <img src="../../assets/home.png" alt="" />
      </a>
    </div>
    <div class="center-content active overlay">
      <div class="row-content columns animate sm">
        <div class="col-4 shift">
          <img
            class="person-img"
            style="margin-right: -11px; width: 175px;"
            src="../../assets/home/sm-b.png"
          />
          <div class="portal-box sm" @click="handleToggle2">
            <div>
              <img src="../../assets/home/portal-2.png" />
            </div>
          </div>
          <div class="portal-info">
            <small>Art</small>
            <h3>Small Business Owner</h3>
            <p class="mt-1">
              Art owns two music stores and is interested in expanding his
              business online. However, Art doesn’t have any experience with
              online stores and isn’t sure how to get started.
            </p>
            <strong class="mb-2" style="display: block"
              >How Dell.com delivers its vision:</strong
            >
            <p>
              Dell.com empowers a small business owner to expand his business
              into e-commerce platforms and elevate his employees’ capabilities
              within the business.
            </p>
            <a href="#/Art" class="primary-button mt-4">View Art's Story</a>
            <a href="/#/Journey" class="close"
              ><img
                src="../../assets/close.png"
                stlye="padding-right:15px"
              />Close</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
</script>

<style scoped>
.col-4 {
  width: 100vw;
  display: flex;
  align-items: center;
}
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/home/overlay-portal.jpg");
  background-size: cover;
  animation: fadeInOPout 1s;
  display: block;
  z-index: 3;
  opacity: 1;
  display: flex;
}
.portal-info {
  margin-left: 90px;
}
.portal-box {
  min-width: 290px;
  margin-top: -5px;
}
.row-content.columns {
  margin: auto;
}
</style>