import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')
/*
window.onscroll = function(ev) {
    const navArrow = document.getElementById('controlArrow')
	const scrollBar = document.getElementById('progress')

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        navArrow.classList.add("hide");
    }else{
        navArrow.classList.remove("hide");
    }
	if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
        scrollBar.classList.add("show");
    }else{
        scrollBar.classList.remove("show");
    }
};
 */



window.addEventListener("scroll", () => {
	updateProgress();
});

function updateProgress() {

    var progressBar = document.getElementById("progress");

    if (progressBar !== null){
    var progressFill = progressBar.firstElementChild;
	let width = progressBar.clientWidth;
	let progress =
		window.pageYOffset / (document.body.scrollHeight - window.innerHeight);
	// fill progress bar
	progressFill.style.width = Math.ceil(100 * progress) + "%";
    }
}

updateProgress();

// Reading progress
let sections = document.getElementsByTagName("section");
let reProgressWrap = document.body.lastElementChild;
let totalWords = 0;
Array.from(sections).forEach(s => {
	let title = 	s.firstElementChild.firstElementChild;
	let wordsInSec = document.createElement("div");
	let ps = s.getElementsByTagName("p");
	let sectionTotal = 0;
	if (ps !== []) {
		ps = Array.from(ps);
		ps.forEach(p => {
			let text = p.innerText;
			let words = text.split(" ");
			sectionTotal = words.length + sectionTotal;

			title.appendChild(wordsInSec);
		});
	}
});

function toggleSection(_s) {
    document.querySelectorAll(".section-anchor").forEach((_anchor) => {
        _anchor.classList.remove('active');
    });
    document.querySelector("[data-section='"+_s+"']").classList.add('active');
}

function initListeners() {
    document.querySelectorAll(".section-anchor").forEach((_anchor) => {
        _anchor.addEventListener("click", function () {
           let section = this.dataset.section;
           toggleSection(section);
        });
    });

    var startTimer = setInterval(function(){
        let active = document.querySelector(".section.content-show");
        if (active) {
            clearInterval(startTimer);
            getActiveSection();
        }
    }, 500);

    let _next = document.getElementById('btn-next');
    if (_next) {
        _next.addEventListener('click', function(){
            setTimeout(getActiveSection, 250);
        });
    }

    let _back = document.getElementById('btn-back');
    if (_back) {
        _back.addEventListener('click', function(){
            setTimeout(getActiveSection, 250);
        });
    }
}

function getActiveSection(){
    let active_elem = Array.from(
        document.querySelectorAll(".section.content-show")
    ).pop();
    let active_class = '';
    if (active_elem) {
        active_elem.classList.forEach(function(_c){
            if (_c.indexOf('-section')!= -1) {
                active_class = _c.split('-');
                toggleSection(active_class[0]);
                return;
            }
        })
    }
}

window.addEventListener('hashchange', initListeners);
window.addEventListener("load", initListeners);


/*
    legacy code
    for scrollable version
*/
/*
document.body.addEventListener("click", function (ev) {

	const sectionsInspire = document.querySelectorAll('.inspire-section');
	const sectionsLearn = document.querySelectorAll('.learn-section');
	const sectionsBuy = document.querySelectorAll('.buy-section');
	const sectionsUse = document.querySelectorAll('.use-section');
    //const sectionsSummary = document.querySelectorAll('.summary-section');

	for (const section of sectionsInspire) {
		if (section.getBoundingClientRect().top <= window.innerHeight * 0.75 && section.getBoundingClientRect().top > 0) {

		  document.getElementById('inspire-link').classList.add('active');
		  document.getElementById('learn-link').classList.remove('active');
		  document.getElementById('buy-link').classList.remove('active');
		  document.getElementById('use-link').classList.remove('active');
		}
	  }

	for (const learn of sectionsLearn) {
	  if (learn.getBoundingClientRect().top <= window.innerHeight * 0.75 && learn.getBoundingClientRect().top > 0) {
		document.getElementById('inspire-link').classList.remove('active');
		document.getElementById('learn-link').classList.add('active');
		document.getElementById('buy-link').classList.remove('active');
		document.getElementById('use-link').classList.remove('active');
	  }
	}
	for (const buy of sectionsBuy) {
		if (buy.getBoundingClientRect().top <= window.innerHeight * 0.75 && buy.getBoundingClientRect().top > 0) {
			document.getElementById('inspire-link').classList.remove('active');
			document.getElementById('learn-link').classList.remove('active');
			document.getElementById('buy-link').classList.add('active');
			document.getElementById('use-link').classList.remove('active');
		}
	  }

	  for (const use of sectionsUse) {
		if (use.getBoundingClientRect().top <= window.innerHeight * 0.75 && use.getBoundingClientRect().top > 0) {
			document.getElementById('inspire-link').classList.remove('active');
			document.getElementById('learn-link').classList.remove('active');
			document.getElementById('buy-link').classList.remove('active');
			document.getElementById('use-link').classList.add('active');
			document.getElementById('controlArrow').classList.remove('hide');
		}
	  }
 	//for (const sum of sectionsSummary) {
	//	if (use.getBoundingClientRect().top <= window.innerHeight * 0.75 && sum.getBoundingClientRect().top > 0) {
	//		document.getElementById('controlArrow').classList.add('hide');
	//	}
	//}

});
*/