<template>
  <section
    class="full-panel"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/intro-2.jpg') + ')',
    }"
  >
    <div id="topLeft">
      <img src="../../assets/logo.svg" alt="" />
    </div>
    <div id="topRight">
      <a href="/">
        <img src="../../assets/home.png" alt="" />
      </a>
    </div>
    <div class="row-content animate">
        <div class="col-5">
            <div class="center-text">
            <h1 class="section-title">A Day in the Life<br/>with Dell.com</h1>
            <p>Welcome to the future of Dell.com. During this experience, you will follow the stories of three Dell.com users as they find inspiration, learn new skills, buy products and services, and use them to achieve their goals. Along their journeys, you will see how Dell.com offers them a more integrated, personalized, and inspiring experience, fulfilling Dell.com’s North Star Vision:</p>
            <p>Dell.com inspires everyone to discover, learn, buy and use technology to unlock their full potential.</p>
            <a href="#/Journey" style="margin-top:80px" class="primary-button">CHOOSE YOUR JOURNEY</a>
            </div>
        </div>
        <div class="col-5">
            <div class="info-box">
                <h3>The Dell.com Pillars</h3>
                <p class="blue" style="margin-bottom:10px">Inspire</p>
                <p>Offering an enjoyable experience that shows users what’s possible.</p>
                <p class="blue" style="margin-top:20px; margin-bottom:10px">Learn</p>
                <p> Educating users about innovative products and services, technology skill sets, and the Dell brand. </p>
                <p class="blue" style="margin-top:20px; margin-bottom:10px">Buy</p>
                <p>Creating a seamless process for customers to discover, purchase, and pay for Dell products and services. </p>
                <p class="blue" style="margin-top:20px; margin-bottom:10px">Use</p>
                <p>Giving customers the tools to get the most out of their products, while providing hands-on support and guidance when needed.</p>
                <a href="#/Pillar">EXPLORE BY PILLAR</a>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Path",
  components: {},
};
</script>
