<template>
  <section class="section" :id="sectionTitle"  :class="sectionClass">
    <div class="container" :class="background">
      <div class="video in-section hide" :class="videoClass" v-if="videoClass">
        <video
          id="v0"
          tabindex="0"
          autobuffer="autobuffer"
          preload="preload"
          autoplay
          muted
        >
          <source
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            src="../assets/stack.mp4"
          />
        </video>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="content">

            <p>
              <h2 class="sub-title-section">{{ sub }}</h2>
              {{ paragraph }}</p>
            <h1 class="title-section">{{ title }}</h1> 
          </div> 
        </div>
        <div class="col-4">
          <div class="content">
              <div id="myID" class="popup">
                <img :src="require(`@/assets/art/${filename}`)" v-if="filename" />
   
                <a v-if="sub" class="primary-button next-button" @click="scroll" href="#/AnnaPillar">Next Story</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Popup from "./popups/popup-template.vue";

export default {
  components: {
    Popup,
  },
  props: {
    msg: String,
    background: String,
    sub: String,
    sectionTitle: String,
    paragraph: String,
    title: String,
    videoClass: String,
    filename: String,
    sectionClass: String,
  },
  methods: {
    scroll(event) {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    //  run the function on all section elements
  },
};
</script>

<style lang="scss">
.section {
  z-index: 1;
  position: relative;
  height: 100vh;
  width: 100vw;
  opacity: 0;
}
.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
}
.popup {
  position: fixed;
  right: 0 !important;
  top: 50% !important;
  transform: translate(0%, -50%);
  width: 50vw !important;
  height: 60vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  img {
    width: 40vw;
  }
}

.blueleftlight {
  background-image: url("@/assets/blue-bg-l.jpg");
}
.bluebottomlight {
  background-image: url("@/assets/blue-bg-lc.jpg");
}
.bluetoplight {
  background-image: url("@/assets/blue-bg-uc.jpg");
}
.bluetopleftlight {
  background-image: url("@/assets/blue-bg-ur.jpg");
}
.buy01 {
  background-image: url("@/assets/hos_sc008_sh005.1001.jpg");
}
.art-inspire01 {
  background-image: url("@/assets/art/art-inspire.jpg");
}
.art-inspire02 {
  background-image: url("@/assets/art/mus_sc002_sh015.1001.jpg");
}
.art-inspire03 {
  background-image: url("@/assets/art/mus_sc002_sh015.1001.jpg");
  img {
    width: 40vh;
  }
}
.art-inspire04 {
  background-image: url("@/assets/blue-bg-l.jpg");
}
.bluebottomlighta {
  background-image: url("@/assets/blue-bg-l.jpg");
  img {
    margin-top: 105px;
  }
}
.art-inspire05 {
  background-image: url("@/assets/art/mus_sc002_sh010.1001.jpg");
  img {
    width: 65vh;
    margin-top: 105px;
  }
}
.art-learn-01 {
  background-image: url("@/assets/art/art-learn-bg.jpg");
}
.art-learn-03 {
  background-image: url("@/assets/blue-bg-lc.jpg");
  img {
    margin-top: 155px;
  }
}
.art-learn-05 {
  background-image: url("@/assets/art/mus_sc004_sh005.1001.jpg");
}
.art-learn-09 {
  background-image: url("@/assets/art/art-left-2.jpg");
}
.art-learn17 {
  background-image: url("@/assets/blue-bg-lc.jpg");
  img {
    width: 120vh !important;
    max-width: 120% !important;
    min-width: 120% !important;
    bottom: 0%;
    position: fixed;
  }
}
.art-use-8 {
  background-image: url("@/assets/blue-bg-lc.jpg");
  img {
    width: 75vh;
    position: fixed;
    bottom: -80%;
  }
}
.art-use-6 {
  background-image: url("@/assets/art/mus_sc007_sh005.100321.jpg");
}
.art-inspire24 {
  background-image: url("@/assets/art/art-com.jpg");
}
.art-inspire29 {
  background-image: url("@/assets/art/art-inspire-29.jpg");
}
.summary {
  background-image: url("@/assets/home/intro-1.jpg");
}
.bottomMenu-show {
  position: fixed;
  width: 300px;
  height: 51px;
  z-index: 1;
  transition: 0.5s;
  box-shadow: 0 4px 10px rgb(0 0 0 / 8%);
  background: #003862;
  color: white;
  top: 98px;
  right: 205px;
  padding: 40px;
}

.content-show {
  top: 0;
  opacity: 1;
}
.content-above {
  top: 0;
}

.content-show .container {
  position: fixed;
  height: 100vh;
}
.content-show .popup div {
  &::nth-child(1) {
    animation: fadeIn 2.1s;
  }
  &::nth-child(1) {
    animation: fadeIn 2.1s;
  }
  &::nth-child(1) {
    animation: fadeIn 2.1s;
  }
}

.content p {
  position: sticky;
  position: -webkit-sticky;
  /* top: 33.3%; */
  font-family: Roboto;
  font-size: 32px;
  text-align: left;
  color: white;
  width: 33vw;
  padding: 45px;
  margin: 60px;
  /* background-image: url("@/assets/Left-text-gradient.png"); */
}

h1 {
  font-size: 3em;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0;
}
.indent-1 {
  float: left;
}
.indent-1-show {
  float: left;
  top: 0;
  opacity: 1;
  animation: fadeIn 2s;
}
.indent-1 section {
  width: 50%;
  float: left;
}
.hide {
  opacity: 0;
  display: none;
}
.show {
  opacity: 1;
}
p {
  font-size: 1.5em;
}

.entered {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

#portal {
  /* background-image: url('/men_sc001_sh005.1001.png'); */
  height: 100vh;
  width: 100vw;
  bottom: 60px;
  right: 60px;
}
#topLeft {
  position: relative;
}
#topLeft img {
  top: 5%;
  left: 3%;
  position: fixed;
  z-index: 2;
}
#topRight {
  position: relative;
}
#topRight img {
  top: 5%;
  right: 5%;
  position: fixed;
  z-index: 2;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeInPop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
