<template id="home">
  <BackgroundVidVue />
  <NicoleUI />
  <PillarsModal></PillarsModal>
  <NicolePortal1
    v-for="post in posts"
    :key="post.id"
    :paragraph="post.paragraph" 
    :title="post.title" 
    :sub="post.sub"
    :sectionTitle="post.sectionTitle"
    :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :filename="post.filename"
  />
</template>
<script>
import BackgroundVidVue from "../components/BackgroundVid.vue";
import NicoleUI from "../components/NicoleUI.vue";
import NicolePortal1 from "../components/NicolePortal1.vue";
import PillarsModal from "../components/PillarsModal.vue";

export default {
  name: "Nicole",
  components: {
    BackgroundVidVue,
    NicoleUI,
    NicolePortal1,
    PillarsModal,
  },
  mounted() {},
  data() {
    return {
      posts: [
        {
          id: 1,
          paragraph: "",
          background: "inspire01",
          title: "Inspire",
          sectionTitle: "inspire",
          sectionClass: "inspire-section"
        },
        {
          id: 2,
          paragraph:
            "Back in January, Nicole attended Dell Technologies World, where she was able to attend the Healthcare break-out session. ",
          background: "inspire02-1",
                    sectionClass: "inspire-section"
        },
        {
          id: 4,
          paragraph: "Dell hosted an interactive experience that showed how integrated hardware management and machine learning can help healthcare professionals track and measure treatment efficacy across hospital systems.",
          background: "inspire11",
          sectionClass: "inspire-section",
          videoClass: "show"
        },
        {
          id: 5,
          paragraph:
            "Months later, when Nicole is tasked with a technology transition at her hospital, she remembers the impressive experience at Dell Technologies World.",
          background: "inspire06",
          sectionClass: "inspire-section",

        },
        {
          id: 7,
          paragraph:
            "After Nicole talks with a Dell representative about her goals, concerns and needs, the representative suggests Dell’s white-glove service and APEX solutions.  ",
          background: "inspire08",
          filename: "RepMsg.png",
          sectionClass: "inspire-section"
        },
          {
          id: 8,
          paragraph:
            "With APEX, Nicole will have a multi-cloud, multi-data center environment to manage IT operations efficiently and effectively. ",
          background: "inspire11",
          filename: "itdm-com.png",
          sectionClass: "inspire-section"
        },
        {
          id: 9,
          paragraph:
            "The Dell dedicated partner will help gain alignment among stakeholders across departments at the hospital. ",
          background: "inspire10-1",
          filename: "Inspire-People.png",
          sectionClass: "inspire-section"
        },

        {
          id: 10,
          paragraph:
            "Nicole is very excited at this prospect and sets up an appointment with Charlie, a Dell Healthcare Expert who will lead her dedicated white-glove service team. ",
          background: "inspire11",
          filename: "calendar.png",
          sectionClass: "inspire-section"
        },
        {
          id: 11,
          paragraph: " ",
          background: "learn01",
          title: "Learn",
          sectionTitle: "learn",
          sectionClass: "learn-section"
        },
        {
          id: 12,
          paragraph:
            "During Nicole’s consultation with Charlie, he creates an authenticated Dell account for her and inputs all the needs and specifications for the hospital’s transition. ",
          background: "bluetoplight",
          filename: "NicoleAcct1.png",
          sectionClass: "learn-section"
        },
        {
          id: 13,
          paragraph:
            "Nicole’s authenticated account offers a central hub to monitor her projects.",
          background: "bluebottomlight",
          filename: "NicoleAcct2.png",
          sectionClass: "learn-section"
        },
/*         {
          id: 14,
          paragraph:
            "Nicole’s authenticated account offers a central hub to monitor her projects.",
          background: "inspire10-2",
          filename: "NicoleAcct3.png",
          sectionClass: "learn-section"
        }, */
        {
          id: 15,
          paragraph:
            "Her account also allows Charlie to send her relevant articles, provide resources to help her conversations with stakeholders, and manage the entire onboarding process.",
          background: "learn02",
          filename: "NicoleAcct4.png",
          sectionClass: "learn-section"
        },
        {
          id: 16,
          paragraph:
            "Over the following weeks, Charlie and his team help Nicole prepare presentations for different stakeholders.",
          background: "inspire12",
          filename: "pres1.png",
          sectionClass: "learn-section"
        },
        {
          id: 17,
          paragraph:
            "Each presentation addresses different department’s specific concerns.",
          background: "learn02",
          filename: "pres2.png",
          sectionClass: "learn-section"
        },
        /* {
          id: 18,
          paragraph:
            "Each presentation addresses different department’s specific concerns.",
          background: "inspire10",
          filename: "pres3.png",
          sectionClass: "learn-section"
        },
        {
          id: 19,
          paragraph:
            "Each presentation addresses different department’s specific concerns.",
          background: "learn02",
          filename: "pres4.png",
          sectionClass: "learn-section"
        }, */
        {
          id: 20,
          paragraph:
            "The presentations are all housed in a unified hub on Dell.com.",
          background: "inspire10",
          filename: "pres5.png",
          sectionClass: "learn-section"
        },

        {
          id: 22,
          paragraph:"As Nicole presents to stakeholders, Charlie also attends the meetings, taking notes, and adjusting information to accommodate stakeholders’ feedback.",
          background: "learn11",
          sectionClass: "learn-section"
        },
        {
          id: 23,
         paragraph:
            "By the end of this review process, all the stakeholders are knowledgeable of the plan forward and aligned with the purchase.",
          
          background: "checkmarks-bg",
          filename: "checkmarks.png",
        },
        {
          id: 25,
          paragraph: "",
          background: "buy01",
          title: "Buy",
          sectionTitle: "buy",
          sectionClass: "buy-section"
        },
        {
          id: 26,
          paragraph:
            "With his historical knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "charliebackground",
          filename: "charliepopup.png",
          sectionClass: "buy-section"
        },
/*         {
          id: 27,
          paragraph:
            "With his knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "blueleftlight",
          filename: "buy3.png",
          sectionClass: "buy-section"
        }, */
/*         {
          id: 28,
          paragraph:
            "With his knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "inspire10",
          filename: "buy4.png",
          sectionClass: "buy-section"
        }, */
/*         {
          id: 29,
          paragraph:
            "With his knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "inspire11",
          filename: "buy5.png",
          sectionClass: "buy-section"
        }, */
        {
          id: 30,
          paragraph:
            "Charlie’s team also compiles digitized forms into a central location on Dell.com so that completing the purchasing agreement is as simple as possible.",
          background: "learn12",
          filename: "buy6.png",
          sectionClass: "buy-section"
        },
/*         {
          id: 31,
          paragraph:
            "Charlie’s team also compiles digitized forms into a central location on Dell.com so that completing the purchasing agreement is as simple as possible.",
          background: "blueleftlight",
          filename: "buy7.png",
          sectionClass: "buy-section"
        }, */
        {
          id: 32,
          paragraph: "",
          background: "learn01",
          title: "Use",
          sectionTitle: "use",
          sectionClass: "use-section"
        },
        {
          id: 33,
          paragraph:
            "As the hospital’s transition to the new machines begins, Charlie brings on a team of Dell.com experts who will help onboard hospital employees.",
          background: "dellteambg",
          filename: "",
          sectionClass: "use-section"
        },
        {
          id: 34,
          paragraph:
            "Nicole also works with the Dell.com team to integrate Dell’s API into a unified APEX Console across the hospital, giving her a view to monitor machines, applications, and analytics in real time.",
          background: "learn02",
          filename: "use2-1.png",
          sectionClass: "use-section"
        },
        {
          id: 35,
          paragraph:
            "Moving forward, Nicole has a reliable team at Dell.com who is knowledgeable of the hospital and its needs and can offer support whenever it is needed.",
          background: "inspire11",
          filename: "use4.png",
          sectionClass: "use-section"
        },
        {
          id: 36,
          sub:"Summary",
          paragraph:"Dell.com’s white-glove service and partnership helped Nicole successfully evolve the infrastructure of the hospital system, while meeting the organization’s different needs and requests.",
          background: "summary",
          filename: "summary.png",
          sectionClass: "summary-section"
        },
      ],
    };
  },
};
</script>
