<template>
  <section class="popup">
    <div id="myID" class="popup">
      <img :src="img" />
    </div>
  </section>
</template>

<script>
export default {
  name: "Popup",
  props: {
    img: String,
  },
};
</script>

<style lang="scss" scoped>
.popup {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(-25%, -50%);
  height: 70vh;
  width: 50vw;
}
</style>
