<template id="home">
  <AnaVid/>
  <AnaUI />
  <PillarsModal></PillarsModal>
  <AnaPortal
    v-for="post in posts"
    :key="post.id"
    :sub="post.sub"
    :paragraph="post.paragraph"
    :title="post.title"
    :sectionTitle="post.sectionTitle"
    :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :usermessage="post.usermessage"
    :filename="post.filename"
  />
</template>
<script>
import AnaVid from "../components/AnaVid.vue";
import AnaUI from "../components/AnaUI.vue";
import AnaPortal from "../components/AnaPortal.vue";
import PillarsModal from "../components/PillarsModal.vue";

export default {
  name: "Art",
  components: {
    AnaVid,
    AnaUI,
    AnaPortal,
    PillarsModal
  },
  mounted() {
    var v = document.getElementById("v0");
    var vc = document.getElementById("video-section");
    v.play();
    v.addEventListener(
      "ended",
      function () {
        vc.classList.add("video-done");
      },
      false
    );
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          paragraph: "",
          background: "ana-inspire01",
          title: "Inspire",
          sectionTitle: "inspire",
          sectionClass: "inspire-section"
        },
        {
          id: 2,
          paragraph:"Three years ago, Anna’s high school partnered with Dell to implement a new STEM initiative for students.",
          background: "ana-inspire02",
          sectionClass: "inspire-section"
        },
        {
        id: 3,
          paragraph:"Anna’s school provided her with a Dell computer and a suite of software.",
          background: "ana-inspire03",
          sectionClass: "inspire-section"

        },
        {
        id: 5,
          paragraph:"Anna first became interested in computer engineering while using this Dell equipment. ",
          background: "ana-inspire05",
          filename:"inspire.png",
          sectionClass: "inspire-section"
        },

        {
        id: 6,
          paragraph:"As Anna gets ready for college, she is eager to continue using a Dell computer because she knows it will meet her needs, both for engineering and for gaming.",
          background: "ana-inspire06",
          filename:"girl.png",
          sectionClass: "inspire-section"
        },

        {
          id: 7,
          paragraph: "",
          background: "ana-inspire07",
          title: "Learn",
          sectionTitle: "learn",
          sectionClass: "learn-section"
        },
        {
        id: 8,
          paragraph:"Anna goes to Dell.com to research laptops and quickly comes across an assessment tool that helps determine the best components for a custom machine.",
          background: "ana-inspire08",
          filename:"Learn- Interactive Assessment.png",
          sectionClass: "learn-section"
        },
        {
        id: 9,
          paragraph:"Anna takes the assessment, inputting her engineering and gaming needs, as well as her desires for her custom machine. ",
          background: "ana-inspire10",
          filename:"Learn- Interactive Assessment 01.png",
          sectionClass: "learn-section"
        },
        {
        id: 11,
          paragraph: "At the end of the assessment, Dell.com   generates several configurations perfect for Anna’s needs. ",
          background: "ana-inspire12",
          filename:"Learn-Dashboard.png",
          sectionClass: "Learn-section"
        },
        {
        id: 12,
          paragraph:"After considering her options, including her budget, Anna selects a custom laptop with confidence. ",
          background: "ana-inspire04",
          sectionClass: "learn-section"
        },
        {
        id: 13,
          paragraph: "",
          background: "ana-inspire14",
          title: "Buy",
          sectionTitle: "buy",
          sectionClass: "buy-section"
        },
/*         {
         id: 14,
          paragraph:"As she is checking out, Anna is given the option to create a student account, which will give her a discount on her order.",
          background: "ana-inspire15",
          sectionClass: "buy-section"
        }, */
        {
          id: 15,
          paragraph:"As she is checking out, Anna is given the option to create a student account, which will give her a discount on her order.",
          background: "ana-inspire15",
          filename:"Buy-Account.png",
          sectionClass: "buy-section"
        },
        {
         id: 16,
          paragraph:"She will also get access to Dell student resources tailored to her studies, including tutorials in C++.",
          background: "ana-inspire16",
          filename:"Use-Resources.png",
          sectionClass: "buy-section"
        },

        {
        id: 18,
          paragraph:"Anna creates her Dell student account and moves forward with her discounted purchase.",
          background: "ana-inspire18",
          filename:"Buy-Notification.png",
          sectionClass: "buy-section"
        },
        {
        id: 19,
          paragraph: "",
          background: "ana-inspire19",
          title: "Use",
          sectionTitle: "use",
          sectionClass: "use-section"
        },
        {
        id: 20,
          paragraph:"During her first semester, Anna has a lot going on, coding, gaming, and streaming videos all at the same time.",
          background: "ana-inspire20",
          sectionClass: "use-section"
        },
        {
        id: 21,
          paragraph:"Because Anna has a student account, Dell.com offers Anna  free optimizations and emergency support. ",
          background: "ana-inspire21",
          filename:"anna-resources-1.png",
          sectionClass: "use-section"
        },
        {
        id: 22,
          paragraph:"Dell.com notices the uptick in usage and sends her options to optimize for her approval.",
          background: "ana-inspire22",
          filename:"anna-resources-2.png",
          sectionClass: "use-section"
        },
        {
          id: 23,
          paragraph: "Anna sees an immediate improvement in performance. ",
          background: "ana-inspire22",
          filename:"anna-resources-3.png",
          sectionClass: "use-section"
        },
        {
          id: 24,
          sub:"Summary",
          paragraph: "She rests easy knowing that Dell.com will continue to help her get the most out of her education.",
          background: "ana-inspire24",
          filename:"anna-resources-4.png",
          sectionClass: "use-section"
        },
        {
          id: 25,
          sub:"Summary",
          paragraph:" Dell.com helped a student design and build a machine that was perfect for her educational and entertainment needs. Dell.com also provided her with an educational center for her to learn new skills and tools. ",
          background: "summary",
          filename: "summary.png",
        },
      ],
    };
  },
};
</script>
