<template id="home">
  <ArtVid/>
  <ArtUI /> 
  <PillarsModal></PillarsModal> 
  <ArtPortal
    v-for="post in posts" 
    :key="post.id"
    :paragraph="post.paragraph"
    :title="post.title"
    :sub="post.sub"
    :sectionTitle="post.sectionTitle"
        :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :filename="post.filename"
  />
</template>
<script>
import ArtVid from "../components/ArtVid.vue";
import ArtUI from "../components/ArtUI.vue";
import ArtPortal from "../components/ArtPortal.vue";
import PillarsModal from "../components/PillarsModal.vue";

export default {
  name: "Art",
  components: {
    ArtVid,
    ArtUI,
    ArtPortal,
    PillarsModal
  },
  mounted() {
    var v = document.getElementById("v0");
    var vc = document.getElementById("video-section");
    v.play();
    v.addEventListener(
      "ended",
      function () {
        vc.classList.add("video-done");
      },
      false
    );
  },
  data() {
    return {
      posts: [
        {
          id: 1,
          paragraph: "", 
          background: "art-inspire01",
          title: "Inspire",
          sectionTitle: "inspire",
          sectionClass: "inspire-section"
        },
        {
          id: 2,
          paragraph:"Art notices that another small business in his community has an impressive new online store. ",
          background: "art-inspire02",
          sectionClass: "inspire-section"
        },
        {
        id: 3,
          paragraph:"Art texts the small business owner to ask which vendor they used to build their site. They tell Art that they were able to set it up themselves using a Dell server and the Small Business Center on Dell.com.",
          background: "art-inspire03",
          filename:"section-3.png",
          sectionClass: "inspire-section"
        },
        {
        id: 4,
          paragraph:"They send Art a link to The Small Business Center on Dell.com.",
          background: "art-inspire05",
          filename:"section-5.png",
          sectionClass: "inspire-section"
        },
        {
          id: 5,
          paragraph: "", 
          background: "art-learn-01",
          title: "Learn",
          sectionTitle: "learn",
          sectionClass: "learn-section"
        },
        {
        id: 7,
          paragraph:"Art visits the Small Business Center on Dell.com and finds relevant resources for small businesses looking to take their business online.",
          background: "bluebottomlight",
          filename:"art-learn-2.png",
          sectionClass: "learn-section"
        },
        {
        id: 8,
          paragraph:"There is an interactive assessment that will help Art decide what he needs to build out his ecommerce site.",
          background: "art-learn-03",
          filename:"art-learn-3.png",
          sectionClass: "learn-section"
        },
        {
        id: 9,
          paragraph:"The assessment follows a decision tree format and uses machine learning to predict and suggest relevant pathways for Art to follow.",
          background: "bluetopleftlight",
           filename:"art-learn-4.png",
           sectionClass: "learn-section"
        },
        {
        id: 10,
          paragraph:"It asks about Art’s employees, ",
          background: "art-learn-05",
          filename:"art-learn-5.png",
          sectionClass: "learn-section"
        },
        {
         id: 11,
          paragraph:"and their accessibility needs.",
          background: "art-learn-05",
           filename:"art-learn-6.png",
           sectionClass: "learn-section"
        },
        {
          id: 13,
          paragraph:"Art isn’t familiar with accessibility services, but he wants to learn more because two of his employees have visual impairments.",
          background: "bluetoplight",
           filename:"art-learn-7.png",
           sectionClass: "learn-section"
        },
        {
          id: 14,
          paragraph:"Once he has learned more, Art is eager to implement these services to improve his employee’s experiences at the store and within his new e-commerce platform.",
          background: "blueleftlight",
           filename:"art-learn-8.png",
           sectionClass: "learn-section"
        },

        {
        id: 16,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
           filename:"art-learn-10.png",
           sectionClass: "learn-section"
        },
       /*  {
        id: 17,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
          filename:"art-learn-11.png",
          sectionClass: "learn-section"
        },
        {
         id: 18,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
          filename:"art-learn-12.png",
          sectionClass: "learn-section"
        },
        {
         id: 19,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
          filename:"art-learn-13.png",
          sectionClass: "learn-section"
        }, */
/*         { 
         id: 20,
          paragraph:"This includes servers and storage for his e-commerce site, as well as deployment services from Dell.com.",
          background: "art-inspire04",
          videoClass: "show",
          sectionClass: "learn-section"
        }, */
        {
         id: 21,
          paragraph:"Art is prompted to create an authenticated account on Dell.com so that he can save these results and resources for later.",
          background: "art-inspire04",
          filename:"art-learn-14.png",
          sectionClass: "learn-section"
        },
        {
        id: 22,
          paragraph:"Art quickly makes his account and seamlessly returns to his results page.",
          background: "art-inspire04",
              filename:"art-learn-15.png",
              sectionClass: "learn-section"
        },
  
        {
        id: 24,
          paragraph:"Art clicks into the product pages and watches video testimonials from small business owners who have used these products and services to grow their businesses online. ",
          background: "art-inspire04",
          filename:"art-learn-16.png",
          sectionClass: "learn-section"
        },
        {
        id: 25,
          paragraph:"This helps Art feel more confident in the products and his own ability to use them.",
          background: "art-learn17",
          filename:"art-learn-17.png",
          sectionClass: "learn-section"
        },
        {
          id: 26,
          paragraph: "", 
          background: "art-inspire01",
          title: "Buy",
          sectionTitle: "buy",
          sectionClass: "buy-section"
        },
        {
        id: 27,
          paragraph:"After reviewing everything, Art selects one of the personalized server packages from the assessment results page and adds in an accessibility service as well.",
          background: "art-inspire04",
          filename:"art-buy-1.png",
          sectionClass: "buy-section"
        },
        {
          id: 28,
          paragraph:"Once Art completes his purchase, he also receives a message through his authenticated Dell account with helpful resources regarding the products and services he bought.",
          background: "art-inspire04",
          filename:"art-buy-2.png",
          sectionClass: "buy-section"
        },
        {
          id: 29,
          paragraph: "", 
          background: "art-inspire29",
          title: "Use",
          sectionTitle: "use",
          sectionClass: "use-section"
        },
        {
          id: 30,
          paragraph: "With a Dell.com account, Art will also receive technology updates, small business innovation content, and prioritized customer service.", 
        background: "art-inspire04",
          filename:"art-use-1.png",
          sectionClass: "use-section"
        },
/*         {
          id: 31,
          paragraph: "With a Dell.com account, Art will also receive technology updates, small business innovation content, and prioritized customer service.", 
        background: "bluebottomlight",
          filename:"art-use-2.png",
          sectionClass: "use-section"
        }, */
        {
          id:32,
          paragraph: "Through his Dell.com account, Art taps into an online community of small business owners like himself. ", 
        background: "bluetoplight",
          filename:"art-use-3.png",
          sectionClass: "use-section"
        },
        {
          id: 33,
          paragraph: "As Art is getting his e-commerce server up and running, he’s able to discuss digital retail with other business owners online. ", 
        background: "art-inspire04",
          filename:"art-use-4.png",
          sectionClass: "use-section"
        },
        {
          id: 34,
          paragraph: "Weeks later, the music store e-commerce site is up and running, and things are going well.", 
        background: "bluebottomlight",
          filename:"art-use-5.png",
          sectionClass: "use-section"
        },
        {
          id: 35,
          paragraph: "However, one day, Art’s employee who is visually impaired is having trouble accessing the e-commerce platform. ", 
        background: "art-use-6",
        sectionClass: "use-section"
        },
        {
          id: 36,
          paragraph: "Fortunately, she is able to use a screen reader to access the music store’s business account on Dell.com.", 
        background: "art-inspire04",
          filename:"art-use-6.png",
          sectionClass: "use-section"
        },
        {
          id: 37,
          paragraph: "Once on the account dashboard, she is able to speak with a Dell.com Representative who is specially trained to help people with accessibility needs.", 
        background: "art-inspire04",
          filename:"art-use-7.png",
          sectionClass: "use-section"
        },
        {
          id: 38,
          paragraph: "The Dell representative is able to help Art’s employee solve the issue quickly and easily.", 
        background: "art-use-8",
          filename:"art-use-8.png",
          sectionClass: "use-section"
        },
        {
        id: 39,
          sub:"Summary",
          paragraph:"Dell.com educated and empowered a small business owner to expand his business into e-commerce platforms with Dell products and services. Additionally, Dell.com helped him to meet the needs of his employees and elevate their capabilities within the business.",
          background: "summary",
          filename: "summary.png",
          sectionTitle: "summary",
        },
      ],
    };
  },
};
</script>
