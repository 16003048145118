<template>
  <section
    class="full-panel"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/intro-1.jpg') + ')',
    }"
  >
    <div id="topLeft">
        <a href="/">
          <img src="../../assets/logo.svg" alt="" />
        </a>
    </div>
    <div id="topRight">
      <a href="/">
        <img src="../../assets/home.png" alt="" />
      </a>
    </div>
    <div class="offset-center-text animate">
      <div>
        <h1 class="section-title">The Future of Dell.com</h1>
        <p>As Dell Technologies moves toward a more progressive future, we have established a North Star Vision to guide our efforts around Dell.com:</p>
        <strong class="mb-2" style="display:block">Dell.com inspires everyone to discover, learn about, buy and use technology to unlock their full potential.</strong>
        <p>This is your invitation to come along with us as we bring our vision to life. In this experience, you will see how Dell.com can and will evolve to become a more integrated, personalized, and inspiring part of all our users’ journeys.</p>
        <a href="#/Path" class="primary-button mt-4" style="display:inline-block">Continue</a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Future",
  components: {},
};
</script>
