<template>
  <Loader />
  <section class="video" id="video-section">
    <div id="setHeight"></div>
    <video
      id="v0"
      tabindex="0"
      autobuffer="autobuffer"
      preload="preload"
      autoplay
      muted
    >
      <source
        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
        src="../assets/music.mp4"
      />
    </video>
  </section>
</template>

<script>
import { $dataMetaSchema } from "ajv";
import Loader from "../views/Loader.vue";

export default {
  name: "ArtVid",
  components: { Loader },
  props: {
    msg: String,
  },
  mounted() {
    var v = document.getElementById("v0");
    v.play();
    v.addEventListener(
      "ended",
      function () {
        document.getElementById("loader-wrapper").classList.add("hide");
        /*  vc.classList.add("video-done"); */
      },
      false
    );
  },
};
</script>

<style scoped>
.video {
  height: 100vh;
}
video {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#v0 {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
}

#set-height {
  display: block;
}

section {
  z-index: 1;
  position: relative;
}

.content {
  position: sticky;
  position: -webkit-sticky;
  /* top: 33.3%; */
  font-family: Roboto;
  font-size: 35px;
  text-align: left;
  color: white;
  width: 700px;
  padding: 45px;
  margin: 60px;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-7-4 16:24:12
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
</style>
