<template>
  <section
    class="full-panel"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/journey.jpg') + ')',
    }"
  >
    <div id="topLeft">
      <a href="/">
        <img src="../../assets/logo.svg" alt="" />
      </a>
    </div>
    <div id="topRight">
      <a href="/">
        <img src="../../assets/home.png" alt="" />
      </a>
    </div>
    <div class="center-content active overlay">
      <div class="row-content columns animate sm">
        <div class="col-4 shift">
          <img class="person-img" src="../../assets/home/consumer.png" />
          <div class="portal-box sm" @click="handleToggle3">
            <div>
              <img src="../../assets/home/portal-3.png" />
            </div>
          </div>
          <div class="portal-info">
            <small>Anna</small>
            <h3>Consumer</h3>
            <p class="mt-1">
              Anna is getting ready to go off to college. She is majoring in
              software engineering and needs a new laptop that is optimized for
              writing code. Anna is also a casual gamer and wants her machine to
              run games on Steam as well.
            </p>
            <strong class="mb-2" style="display: block"
              >How Dell.com delivers its vision:</strong
            >
            <p>
              Dell.com is guiding a student’s search for the right machine that
              can both progress her education and be a source of personal
              fulfillment.
            </p>
            <a href="#/Anna" class="primary-button mt-4">View Ana's Story</a>
            <a href="/#/Journey" class="close"
              ><img
                src="../../assets/close.png"
                stlye="padding-right:15px"
              />Close</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
</script>

<style scoped>
.col-4 {
  width: 100vw;
  display: flex;
  align-items: center;
}
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/home/overlay-portal.jpg");
  background-size: cover;
  animation: fadeInOPout 1s;
  display: block;
  z-index: 3;
  opacity: 1;
  display: flex;
}
.portal-info {
  margin-left: 90px;
}
.portal-box {
  min-width: 290px;
  margin-top: -5px;
}
.row-content.columns {
  margin: auto;
}
</style>