
<template>
  <component :is="currentView" />
</template>
<script>
import Home from './views/Home.vue'
import Future from './views/intro/Future.vue'
import Path from './views/intro/Path.vue'
import Pillar from './views/intro/Pillar.vue'
import Journey from './views/intro/Journey.vue'
import Nicole from './views/Nicole.vue'
import Art from './views/Art.vue'
import Anna from './views/Anna.vue'
import ArtsPillar from './views/intro/ArtsPillar.vue'
import AnnaPillar from './views/intro/AnnaPillar.vue'
import NicolePillar from './views/intro/NicolePillar.vue'
import Inspire from './views/Inspire.vue'
import Learn from './views/Learn.vue'
import Use from './views/Use.vue'
import Buy from './views/Buy.vue'

const routes = {
  '/': Home,
  '/Future': Future,
  '/Path': Path,
  '/Pillar': Pillar,
  '/Journey': Journey,
  '/Nicole': Nicole,
  '/Art': Art,
  '/Anna': Anna,
  '/ArtsPillar': ArtsPillar,
  '/AnnaPillar': AnnaPillar,
  '/NicolePillar': NicolePillar,
  '/Inspire': Inspire,
  '/Learn': Learn,
  '/Use': Use,
  '/Buy': Buy,
}

export default {
  data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] 
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
		  this.currentPath = window.location.hash
		})
  }
}
</script>
<style>
@import './assets/css/global.css';

</style>
