<template id="home">
  <LearnUI />
  <LearnPortal
    v-for="post in posts"
    :key="post.id"
    :paragraph="post.paragraph"
    :title="post.title"
    :sectionTitle="post.sectionTitle"
        :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :filename="post.filename"
  />
</template>
<script>
import LearnUI from "../components/LearnUI.vue";
import LearnPortal from "../components/LearnPortal.vue";


export default {
  name: "Learn",
  components: {
    LearnUI,
    LearnPortal,
  },
  mounted() {

  },
  data() {
    return {
      posts: [
       {
          id: 1,
          paragraph: " ",
          background: "learn01",
          title: "Learn",
          sectionTitle: "nicole",
          sectionClass: "nicole-section",
        },
        {
          id: 2,
          paragraph:
            "During Nicole’s consultation with Charlie, he creates an authenticated Dell account for her and inputs all the needs and specifications for the hospital’s transition. ",
          background: "bluetoplight",
          filename: "nicole/NicoleAcct1.png",
          sectionClass: "nicole-section",
        },
        {
          id: 3,
          paragraph:
            "Nicole’s authenticated account offers a central hub to monitor her projects.",
          background: "bluebottomlight",
          sectionClass: "nicole-section",
          filename: "nicole/NicoleAcct2.png",
        },
/*         {
          id: 4,
          paragraph:
            "Nicole’s authenticated account offers a central hub to monitor her projects.",
          background: "inspire10-2",
          filename: "nicole/NicoleAcct3.png",
        }, */
        {
          id: 5,
          paragraph:
            "Her account also allows Charlie to send her relevant articles, provide resources to help her conversations with stakeholders, and manage the entire onboarding process.",
          background: "learn02",
          sectionClass: "nicole-section",
          filename: "nicole/NicoleAcct4.png",
        },
        {
          id: 6,
          paragraph:
            "Over the following weeks, Charlie and his team help Nicole prepare presentations for different stakeholders.",
          background: "inspire12",
          sectionClass: "nicole-section",
          filename: "nicole/pres1.png",
        },
        {
          id: 7,
          paragraph:
            "Each presentation addresses different department’s specific concerns.",
          background: "learn02",
          sectionClass: "nicole-section",
          filename: "nicole/pres2.png",
        },
        /* {
          id: 8,
          paragraph:
            "Each presentation addresses different department’s specific concerns.",
          background: "inspire10",
          filename: "nicole/pres3.png",
        },
        {
          id: 9,
          paragraph:
            "Each presentation addresses different department’s specific concerns.",
          background: "learn02",
          filename: "nicole/pres4.png",
        }, */
        {
          id: 10,
          paragraph:
            "The presentations are all housed in a unified hub on Dell.com.",
          background: "inspire10",
          sectionClass: "nicole-section",
          filename: "nicole/pres5.png",
        },
/*         {
          id: 11,
          paragraph: "",
          background: "learn11",
        }, */
        {
          id: 12,
          paragraph:
            "As Nicole presents to stakeholders, Charlie also attends the meetings, taking notes, and adjusting information to accommodate stakeholders’ feedback.",
                  background: "learn11",
                  sectionClass: "nicole-section",
        },
/*         {
          id: 13,
          paragraph:
            "By the end of this review process, all the stakeholders are knowledgeable of the plan forward and aligned with the purchase.",
          background: "nicole/learn13",
        }, */

        {
          id: 14,
         paragraph:
            "By the end of this review process, all the stakeholders are knowledgeable of the plan forward and aligned with the purchase.",
          
          background: "checkmarks-bg",
          filename: "nicole/checkmarks.png",
          sectionClass: "nicole-section",
        },
        {
        id: 16,
          paragraph:"Art visits the Small Business Center on Dell.com and finds relevant resources for small businesses looking to take their business online.",
          background: "bluebottomlight",
          filename:"art/art-learn-2.png",
          sectionTitle: "art",
          sectionClass: "art-section",
        },
        {
        id: 17,
          paragraph:"There is an interactive assessment that will help Art decide what he needs to build out his ecommerce site.",
          background: "art-learn-03",
          filename:"art/art-learn-3.png",
          sectionClass: "art-section",
        },
        {
        id: 18,
          paragraph:"The assessment follows a decision tree format and uses machine learning to predict and suggest relevant pathways for Art to follow.",
          background: "bluetopleftlight",
           filename:"art/art-learn-4.png",
           sectionClass: "art-section",
        },
        {
        id: 19,
          paragraph:"It asks about Art’s employees, ",
          background: "art-learn-05",
          filename:"art/art-learn-5.png",
          sectionClass: "art-section",
        },
        {
         id:20,
          paragraph:"and their accessibility needs.",
          background: "art-learn-05",
           filename:"art/art-learn-6.png",
           sectionClass: "art-section",
        },
        {
          id: 21,
          paragraph:"Art isn’t familiar with accessibility services, but he wants to learn more because two of his employees have visual impairments.",
          background: "bluetoplight",
           filename:"art/art-learn-7.png",
           sectionClass: "art-section",
        },
        {
          id: 22,
          paragraph:"Once he has learned more, Art is eager to implement these services to improve his employee’s experiences at the store and within his new e-commerce platform.",
          background: "blueleftlight",
           filename:"art/art-learn-8.png",
           sectionClass: "art-section",
        },
      
        {
        id: 24,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
           filename:"art/art-learn-10.png",
           sectionClass: "art-section",
        },
       /*  {
        id: 25,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
          filename:"art/art-learn-11.png"
        },
        {
         id: 26,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
          filename:"art/art-learn-12.png"
        },
        {
         id: 27,
          paragraph:"After Art finishes the assessment, a results page offers him personalized product and service recommendations.",
          background: "art-inspire04",
          filename:"art/art-learn-13.png"
        }, */
/*         { 
         id: 28,
          paragraph:"This includes servers and storage for his e-commerce site, as well as deployment services from Dell.com.",
          background: "art-inspire04",
          videoClass: "show",
          sectionClass: "art-section",
        }, */
        {
         id: 29,
          paragraph:"Art is prompted to create an authenticated account on Dell.com so that he can save these results and resources for later.",
          background: "art-inspire04",
          filename:"art/art-learn-14.png",
          sectionClass: "art-section",
        },
        {
        id: 30,
          paragraph:"Art quickly makes his account and seamlessly returns to his results page.",
          background: "art-inspire04",
              filename:"art/art-learn-15.png",
              sectionClass: "art-section",
        },
      
        {
        id: 32,
          paragraph:"Art clicks into the product pages and watches video testimonials from small business owners who have used these products and services to grow their businesses online. ",
          background: "art-inspire04",
          filename:"art/art-learn-16.png",
          sectionClass: "art-section",
        },
        {
        id: 33,
          paragraph:"This helps Art feel more confident in the products and his own ability to use them.",
          background: "art-learn17",
          filename:"art/art-learn-17.png",
          sectionClass: "art-section",
        },

        {  
          id:34,
          paragraph:"Anna goes to Dell.com to research laptops and quickly comes across an assessment tool that helps determine the best components for a custom machine.",
          background: "ana-inspire08",
          filename:"ana/Learn- Interactive Assessment.png",
          sectionTitle: "anna",
          sectionClass: "anna-section",
        },
        {
        id: 35,
          paragraph:"Anna takes the assessment, inputting her engineering and gaming needs, as well as her desires for her custom machine. ",
          background: "ana-inspire10",
          filename:"ana/Learn- Interactive Assessment 01.png",
          sectionClass: "anna-section",
        },

        {
        id: 37,
          paragraph: "At the end of the assessment, Dell.com   generates several configurations perfect for Anna’s needs. ", 
          background: "ana-inspire12",
          filename:"ana/Learn-Dashboard.png",
          sectionClass: "anna-section",
        },
        {
        id: 38,
          paragraph:"After considering her options, including her budget, Anna selects a custom laptop with confidence. ",
          background: "ana/ana-inspire04",
          sectionClass: "anna-section",
        },
        
      ],
    };
  },
};
</script>
