<template>
  <div class="left-cover"></div>
  <div id="topLeft">
    <a href="/">
      <img src="../assets/logo-home.svg" alt="" />
    </a>
  </div>
  <div id="topRight">
    <a href="/">
      <img src="../assets/home.png" alt="" />
    </a>
  </div>
  <div class="left-nav">
    <div class="persona-nav">
      <a href="/#/ArtsPillar"
        ><img
          src="../assets/ART.png"
          style="z-index: 3; position: relative; width: 90px; height: 90px"
      /></a>
      <span>Art</span>
      <div class="persona-nav_secondary first">
        <a href="/#/NicolePillar"> <img src="../assets/Nicole.png" /></a>
      </div>
      <div class="persona-nav_secondary second">
        <a href="/#/AnnaPillar"><img src="../assets/anna.png" /></a>
      </div>
    </div>
    <ul>
      <li>
        <a
          href="#"
          data-section="inspire"
          class="section-anchor"
          id="inspire-link"
          >Inspire</a
        >
      </li>
      <li>
        <a href="#" data-section="learn" class="section-anchor" id="learn-link"
          >Learn</a
        >
      </li>
      <li>
        <a href="#" data-section="buy" class="section-anchor" id="buy-link"
          >Buy</a
        >
      </li>
      <li>
        <a href="#" data-section="use" class="section-anchor" id="use-link"
          >Use</a
        >
      </li>
    </ul>
  </div>
  <div class="right-nav controlArrow" id="controlArrow">
    <button class="previus scroll-up" id="btn-back">
      <img src="../assets/arrow-left.png" />
    </button>
    <button class="next" id="btn-next">
      <img src="../assets/arrow-right.png" />
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    handleIntersect(entries, observer) {
      var vc = document.getElementById("video-section");
      if (vc != null) {
        vc.classList.add("video-done");
      }
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("content-show");
        } else {
          entry.target.classList.add("content-above");
          entry.target.classList.remove("content-show");
        }
        //prevRatio = entry.intersectionRatio;
      });
    },
    addObserver(el) {
      let observer;
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: [0.1, 0],
      };
      observer = new IntersectionObserver(this.handleIntersect, options);
      observer.observe(el);
    },
  },
  mounted() {
    var v = document.getElementById("v0");
    var vc = document.getElementById("video-section");
    var scope = this;
    v.play();
    v.addEventListener(
      "ended",
      function () {
        vc.classList.add("video-done");
        let allElements = document.getElementsByClassName("section");
        [...allElements].forEach((el) => {
          scope.addObserver(el);
        });
      },
      false
    );

    document.querySelectorAll(".right-nav button").forEach((btn) => {
      btn.addEventListener("click", function () {
        let pageHeight = window.innerHeight;
        if (btn.className.split(" ").includes("scroll-up")) {
          pageHeight *= -1;
        }
        window.scrollBy(0, pageHeight);
      });
    });
    document.querySelectorAll(".section-anchor").forEach((btn) => {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        let t_s_id = this.dataset.section;
        let target_section = document.getElementById(t_s_id);
        target_section.scrollIntoView({ block: "end", inline: "nearest" });
      });
    });
  },
};
</script>
<style scoped lang="scss">
.left-cover {
  position: fixed;
  background-image: url("../assets/left-cover.png");
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  width: 500px;
  left: -194px;
}
.left-nav {
  position: fixed;
  left: 5%;
  top: 40%;
  transform: translate(-40%, 0%);
  z-index: 11;
  ul {
    margin-left: 0;
  }
  li {
    list-style: none;
    text-align: left;
    margin-bottom: 20px;
    a {
      color: white;
      font-size: 0.9vw;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.persona-nav {
  margin-left: 35px;
  margin-top: -80px;
  margin-bottom: 60px;
  span {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    color: white;
    margin: 10px 0 15px;
    text-align: center;
  }
  img {
    z-index: 2;
  }
  &_secondary {
    transform: translate(15px, -120px);
    position: absolute;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-delay: 0s;
    img {
      width: 60px;
    }
  }
  &:hover {
    .first {
      transform: translate(107px, -110px);
      z-index: 1;
    }
    .second {
      transform: translate(80px, -203px);
      z-index: 1;
    }
  }
}
.right-nav {
  position: fixed;
  z-index: 111;
  right: 5%;
  bottom: 5%;
  img {
    width: 1.5vw;
  }
  button {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
#topLeft {
  position: relative;
}
#topLeft img {
  top: 5%;
  width: 356px;
  position: fixed;
  z-index: 2;
}
#topRight {
  position: relative;
}
#topRight img {
  top: 5%;
  right: 5%;
  position: fixed;
  z-index: 2;
}
</style>
