<template id="home">
  <InspireUI />
  <InspirePortal
    v-for="post in posts"
    :key="post.id"
    :paragraph="post.paragraph"
    :title="post.title"
    :sectionTitle="post.sectionTitle"
    :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :filename="post.filename"
  />
</template>
<script>
import InspireUI from "../components/InspireUI.vue";
import InspirePortal from "../components/InspirePortal.vue";


export default {
  name: "Inspire",
  components: {
    InspireUI,
    InspirePortal,
  },
  mounted() {

  },
  data() {
    return {
      posts: [
        {
          id: 1,
          paragraph: "",
          background: "inspire01",
          title: "Inspire",
          sectionTitle: "nicole",
          sectionClass: "nicole-section",
        },
        {
          id: 2,
          paragraph:
            "Back in January, Nicole attended Dell Technologies World, where she was able to attend the Healthcare break-out session.",
          background: "inspire02-1",
          sectionClass: "nicole-section"
        },
        {
          id: 4,
          paragraph: "Dell hosted an interactive experience that showed how integrated hardware management and machine learning can help healthcare professionals track and measure treatment efficacy across hospital systems.",
          background: "inspire11",
          sectionClass: "nicole-section",
          videoClass: "show"
        },
        {
          id: 5,
          paragraph:
            "Months later, when Nicole is tasked with a technology transition at her hospital, she remembers the impressive experience at Dell Technologies World.",
          sectionClass: "nicole-section",
          background: "inspire06",
        },
        {
          id: 7,
          paragraph:
            "After Nicole talks with a Dell representative about her goals, concerns and needs, the representative suggests Dell’s white-glove service and APEX solutions. ",
          background: "inspire08",
          filename: "nicole/RepMsg.png",
          sectionClass: "nicole-section",
        },
                  {
          id: 8,
          paragraph:
            "With APEX, Nicole will have a multi-cloud, multi-data center environment to manage IT operations efficiently and effectively.  ",
          background: "inspire11",
          filename: "nicole/itdm-com.png",
          sectionClass: "nicole-section",
          sectionClass: "inspire-section"
        },
        {
          id: 9,
          paragraph:
            "The Dell dedicated partner will help gain alignment among stakeholders across departments at the hospital.",
          background: "inspire10-1",
          filename: "nicole/StakeholderPops.png",
          sectionClass: "nicole-section",
        },

        {
          id: 10,
          paragraph:
            "Nicole is very excited at this prospect and sets up an appointment with Charlie, a Dell Healthcare Expert who will lead her dedicated dedicated service team. ",
          background: "inspire11",
          filename: "nicole/calendar.png",
          sectionClass: "nicole-section",
        },
        {
          id: 12,
          paragraph:"Art notices that another small business in his community has an impressive new online store. ",
          background: "art-inspire02",
          sectionTitle: "art",
          sectionClass: "art-section",
        },
        {
        id: 13,
          paragraph:"Art texts the small business owner to ask which vendor they used to build their site. They tell Art that they were able to set it up themselves using a Dell server and the Small Business Center on Dell.com.",
          background: "art-inspire03",
          filename:"art/section-3.png",
          sectionClass: "art-section",
        },
        {
        id: 14,
          paragraph:"They send Art a link to The Small Business Center on Dell.com.",
          background: "art-inspire05",
          filename:"art/section-5.png",
          sectionClass: "art-section",
        },
        {
          id:15,
          paragraph:"Three years ago, Anna’s high school partnered with Dell to implement a new STEM initiative for students.",
          background: "ana-inspire02",
          sectionTitle: "anna",
          sectionClass: "anna-section",
        },
        {
        id:16,
          paragraph:"Anna’s school provided her with a Dell computer and a suite of software.",
          background: "ana/ana-inspire03",
          sectionClass: "anna-section",

        },
        {
        id: 18,
          paragraph:"Anna first became interested in computer engineering while using this Dell equipment.",
          background: "ana-inspire05",
          filename:"ana/inspire.png",
          sectionClass: "anna-section",
        },

        {
        id: 19,
          paragraph:"As Anna gets ready for college, she is eager to continue using a Dell computer because she knows it will meet her needs, both for engineering and for gaming.",
          background: "ana-inspire06",
          filename:"ana/girl.png",
          sectionClass: "anna-section",
        },
      ],
    };
  },
};
</script>
