<template>
  <section
    class="full-panel"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/intro-2.jpg') + ')',
    }"
  >
    <div id="topLeft">
        <a href="/">
          <img src="../../assets/logo.svg" alt="" />
        </a>
    </div>
    <div id="topRight">
      <a href="/">
        <img src="../../assets/home.png" alt="" />
      </a>
    </div>
    <div id="bottomLeft">
      <a href="#/Journey" class="bottom-left-button">CHOOSE YOUR JOURNEY</a>
    </div>
    <div id="bottomRight">
      <a href="#/Path" class="bottom-left-button">CHOOSE A Path</a>
    </div>
    <div class="center-content">
    <div class="center-text-center animate" style="text-align:center; display:block;">
          <h1 class="section-title">Choose a Pillar</h1>
          <h4 class="m-0">See how Dell.com brings its pillars to life across</h4>
        <h4 class="mt-0 mb-4"> different users. Select a pillar to begin.</h4>
    </div>
    <div class="row-content animate sm">
        <div class="col-3">
            <div class="info-box sm">
                <p class="blue" style="margin-bottom:10px; font-weight:bold">Inspire</p>
                <p>Offering an enjoyable experience that shows users what’s possible.</p>
                <a href=/#/Inspire>EXPLORE</a>
            </div>
        </div>
        <div class="col-3">
            <div class="info-box sm">
                <p class="blue" style="margin-bottom:10px; font-weight:bold">Learn</p>
                <p>Educating users about innovative products and services, technology skill sets, and the Dell brand. </p>
                <a href=/#/Learn>EXPLORE</a>
            </div>
        </div>
        <div class="col-3">
            <div class="info-box sm">
                <p class="blue" style="margin-bottom:10px; font-weight:bold">Buy</p>
                <p>Creating a seamless process for customers to discover, purchase, and pay for Dell products and services.</p>
                <a href=/#/Buy>EXPLORE</a>
            </div>
        </div>
        <div class="col-3">
            <div class="info-box sm">
                <p class="blue" style="margin-bottom:10px; font-weight:bold">Use</p>
                <p>Giving customers the tools to get the most out of their products, while providing hands-on support and guidance when needed. </p>
                <a href=/#/Use>EXPLORE</a>
            </div>
        </div>
    </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Pillar",
  components: {},
  methods:{}
};
</script>
<style scoped>
#bottomRight{
  position: absolute;
  right:5%;
  bottom:5%;
}
</style>
