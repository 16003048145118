<template id="home">
  <UseUI />
  <UsePortal
    v-for="post in posts"
    :key="post.id"
    :paragraph="post.paragraph"
    :title="post.title"
    :sectionTitle="post.sectionTitle"
    :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :filename="post.filename"
  />
</template>
<script>
import UseUI from "../components/UseUI.vue";
import UsePortal from "../components/UsePortal.vue";

export default {
  name: "Use",
  components: {
    UseUI,
    UsePortal,
  },
  mounted() {},
  data() {
    return {
      posts: [
        {
          id: 1,
          paragraph: "",
          background: "learn01",
          title: "Use",
          sectionTitle: "nicole",
          sectionClass: "nicole-section",
        },
        {
          id: 2,
          paragraph:
            "As the hospital’s transition to the new machines begins, Charlie brings on a team of Dell.com experts who will help onboard hospital employees.",
          background: "dellteambg",
          filename: "",
          sectionClass: "nicole-section",
        },
        {
          id: 3,
          paragraph:
            "Nicole also works with the Dell.com team to integrate Dell’s API into a unified APEX Console across the hospital, giving her a view to monitor machines, applications, and analytics in real time.",
          background: "learn02",
          filename: "nicole/use2-1.png",
          sectionClass: "nicole-section",
        },
        {
          id: 4,
          paragraph:
            "Moving forward, Nicole has a reliable team at Dell.com who is knowledgeable of the hospital and its needs and can offer support whenever it is needed.",
          background: "inspire11",
          filename: "nicole/use4.png",
          sectionClass: "nicole-section",
        },

        {
          id: 6,
          paragraph:
            "With a Dell.com account, Art will also receive technology updates, small business innovation content, and prioritized customer service.",
          background: "art-inspire04",
          filename: "art/art-use-1.png",
          sectionTitle: "art",
          sectionClass: "art-section",
        },
        /*         {
          id: 7,
          paragraph: "With a Dell.com account, Art will also receive technology updates, small business innovation content, and prioritized customer service.", 
        background: "bluebottomlight",
          filename:"art/art-use-2.png"
        }, */
        {
          id: 8,
          paragraph:
            "Through his Dell.com account, Art taps into an online community of small business owners like himself. ",
          background: "bluetoplight",
          filename: "art/art-use-3.png",
          sectionClass: "art-section",
        },
        {
          id: 9,
          paragraph:
            "As Art is getting his e-commerce server up and running, he’s able to discuss digital retail with other business owners online. ",
          background: "art-inspire04",
          filename: "art/art-use-4.png",
          sectionClass: "art-section",
        },
        {
          id: 10,
          paragraph:
            "Weeks later, the music store e-commerce site is up and running, and things are going well.",
          background: "bluebottomlight",
          filename: "art/art-use-5.png",
          sectionClass: "art-section",
        },
        {
          id: 11,
          paragraph:
            "However, one day, Art’s employee who is visually impaired is having trouble accessing the e-commerce platform. ",
          background: "art/art-use-6",
          sectionClass: "art-section",
        },
        {
          id: 12,
          paragraph:
            "Fortunately, she is able to use a screen reader to access the music store’s business account on Dell.com.",
          background: "art-inspire04",
          filename: "art/art-use-6.png",
          sectionClass: "art-section",
        },
        {
          id: 13,
          paragraph:
            "Once on the account dashboard, she is able to speak with a Dell.com Representative who is specially trained to help people with accessibility needs.",
          background: "art-inspire04",
          filename: "art/art-use-7.png",
          sectionClass: "art-section",
        },
        {
          id: 14,
          paragraph:
            "The Dell representative is able to help Art’s employee solve the issue quickly and easily.",
          background: "art-use-8",
          filename: "art/art-use-8.png",
          sectionClass: "art-section",
        },
        {
          id: 16,
          paragraph:
            "During her first semester, Anna has a lot going on, coding, gaming, and streaming videos all at the same time.",
          background: "ana/ana-inspire20",
          sectionTitle: "anna",
          sectionClass: "anna-section",
        },
        {
          id: 17,
          paragraph:
            "Because Anna has a student account, Dell.com offers Anna  free optimizations and emergency support. ",
          background: "ana-inspire21",
          filename: "ana/anna-resources-1.png",
          sectionClass: "anna-section",
        },
        {
          id: 18,
          paragraph:
            "Dell.com notices the uptick in usage and sends her options to optimize for her approval.",
          background: "ana-inspire22",
          filename: "ana/anna-resources-2.png",
          sectionClass: "anna-section",
        },
        {
          id: 19,
          paragraph: "Anna sees an immediate improvement in performance. ",
          background: "ana-inspire23",
          filename: "ana/anna-resources-3.png",
          sectionClass: "anna-section",
        },
        {
          id: 20,
          sub: "Summary",
          paragraph:
            "She rests easy knowing that Dell.com will continue to help her get the most out of her education.",
          background: "ana-inspire24",
          filename: "ana/anna-resources-4.png",
          sectionClass: "anna-section",
        },
      ],
    };
  },
};
</script>
