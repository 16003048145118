<template>
<a href="#/Future">
  <section 
    class="full-panel center-text"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/intro-1.jpg') + ')',
    }"
  >
    <div id="topLeft">
      <img src="../assets/logo.svg" alt="" />
    </div>
    <div style="padding-left:5%">
    <h1 class="site-title" style="margin-left:-20px">DELL.COM</h1>
    <h2 class="sub-title" style="margin-top:0">A Day in the Life</h2>
    </div>
  </section>
  </a>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
