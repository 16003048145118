<template>
  <section
    class="full-panel"
    :style="{
      backgroundImage: 'url(' + require('@/assets/home/journey.jpg') + ')',
    }"
  >
    <div id="topLeft">
        <a href="/">
          <img src="../../assets/logo.svg" alt="" />
        </a>
    </div>
    <div id="topRight">
      <a href="/">
        <img src="../../assets/home.png" alt="" />
      </a>
    </div>
    <div class="center-content" v-bind:class="[{active: isActive1},{active: isActive2},{active: isActive3}]">
        <div class="overlay-portal" v-bind:class="{active: isActive1}">
        </div>
        <div class="overlay-portal" v-bind:class="{active: isActive2}">
        </div>
        <div class="overlay-portal" v-bind:class="{active: isActive3}">
        </div> 
        
        <div class="row-content columns animate sm" >
            <div class="col-4 shift" v-bind:class="{active: isActive1}">
                <img class="person-img" src="../../assets/home/it-pro.png">
                <div class="portal-box sm" @click="handleToggle1" >
                    <div> 
                    <img src="../../assets/home/portal-1.jpg" class="nicole-portal"/>
                    </div>
                </div>
                <p class="title">IT Decision Maker</p>
                <div class="portal-info">
                    <small>Nicole</small>
                    <h3>IT Decision Maker</h3>
                    <p class="mt-1">Nicole is an Enterprise architect who works for a large hospital which is currently undergoing a major effort to update their technology. </p>
                    <strong class="mb-2" style="display:block">How Dell.com delivers its vision:</strong>
                    <p>Dell.com helps a hospital system evolve their IT infrastructure by offering a white-glove experience and a singular partnership, helping an ITDM align various stakeholders across decision-making.</p>
                    <a  class="toggle-nicole primary-button mt-4">View Nicole's Story</a>
                    <a  @click="handleToggle1" class="close"><img src="../../assets/close.png" stlye="padding-right:15px"/>Close</a>
                </div>
            </div>
            <div class="col-4 shift" v-bind:class="{active: isActive2}">
                <img class="person-img" style="margin-right: -11px;  width: 175px;" src="../../assets/home/sm-b.png">
                <div class="portal-box sm"  @click="handleToggle2" >
                    <div>
                    <img src="../../assets/home/portal-2.png"/>
                    </div>
                </div>
                <p class="title">Small Business</p>
                <div class="portal-info">
                    <small>Art</small>
                    <h3>Small Business Owner</h3>
                    <p class="mt-1">Art owns two music stores and is interested in expanding his business online. However, Art doesn’t have any experience with online stores and isn’t sure how to get started. </p>
                    <strong class="mb-2" style="display:block">How Dell.com delivers its vision:</strong>
                    <p>Dell.com empowers a small business owner to expand his business into e-commerce platforms and elevate his employees’ capabilities within the business.</p>
                    <a href="#/Art" class="primary-button mt-4">View Art's Story</a>
                    <a  @click="handleToggle2" class="close"><img src="../../assets/close.png" stlye="padding-right:15px"/>Close</a>
                </div>
            </div>
            <div class="col-4 shift" v-bind:class="{active: isActive3}">
                <img class="person-img" src="../../assets/home/consumer.png">
                <div class="portal-box sm" @click="handleToggle3">
                    <div>
                    <img src="../../assets/home/portal-3.png"/>
                    </div>
                </div>
                <p class="title">Consumer</p>
                <div class="portal-info">
                    <small>Anna</small>
                    <h3>Consumer</h3>
                    <p class="mt-1">Anna is getting ready to go off to college. She is majoring in software engineering and needs a new laptop that is optimized for writing code. Anna is also a casual gamer and wants her machine to run games on Steam as well.</p>
                    <strong class="mb-2" style="display:block">How Dell.com delivers its vision:</strong>
                    <p>Dell.com is guiding a student’s search for the right machine that can both progress her education and be a source of personal fulfillment.</p>
                    <a href="#/Anna" class="primary-button mt-4">View Ana's Story</a>
                    <a  @click="handleToggle3" class="close"><img src="../../assets/close.png" stlye="padding-right:15px"/>Close</a>
                </div>
            </div>
            </div>
                <div class="center-text-center animate mt-3 copy-content" style="text-align: center;display: block;position: absolute;bottom: 5%;">
                <h1 class="section-title">Choose a User Journey</h1>
                <h4 class="m-0">Select a character to begin their journey with Dell.com</h4>
            </div>
        </div>
  </section>
</template>
<script>
export default {
  name: "Journey",
data() {
    return {
      isActive1: false,
      isActive2: false,
      isActive3: false,
    };
  },
  mounted(){
     var nb = document.querySelector('.toggle-nicole');
      nb.onclick = function() {
        nb.parentNode.closest('.shift').classList.add("zoom");
        setTimeout(function(){
        window.location.hash = '#/Nicole'; 
        }, 1500);//wait 2 seconds

      }
  },
  methods: {
    handleToggle1() {
      this.isActive1 = !this.isActive1;
      this.isActive2 = false;
      this.isActive3 = false;
    },
    handleToggle2() {
      this.isActive1 = false;
    this.isActive2 = !this.isActive2;
      this.isActive3 = false;
    },
    handleToggle3() {
      this.isActive1 = false;
      this.isActive2 = false;
      this.isActive3 = !this.isActive3;
    },
    
  }
};
</script>
