<template id="home">
  <BuyUI />
  <BuyPortal
    v-for="post in posts"
    :key="post.id"
    :paragraph="post.paragraph"
    :title="post.title"
    :sectionTitle="post.sectionTitle"
        :sectionClass="post.sectionClass"
    :background="post.background"
    :videoClass="post.videoClass"
    :filename="post.filename"
  />
</template>
<script>
import BuyUI from "../components/BuyUI.vue";
import BuyPortal from "../components/BuyPortal.vue";


export default {
  name: "Buy",
  components: {
    BuyUI,
    BuyPortal,
  },
  mounted() {

  },
  data() {
    return {
      posts: [
        {
          id: 1,
          paragraph: "",
          background: "buy01",
          title: "Buy",
          sectionTitle: "nicole",
          sectionClass: "nicole-section",
        },
        {
          id: 2,
          paragraph:
            "With his historical knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "charliebackground",
          filename: "nicole/charliepopup.png",
          sectionClass: "nicole-section",
        },
/*         {
          id: 3,
          paragraph:
            "With his knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "blueleftlight",
          filename: "nicole/buy3.png",
        }, */
/*         {
          id: 4,
          paragraph:
            "With his knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "inspire10",
          filename: "nicole/buy4.png",
        }, */
/*         {
          id: 5,
          paragraph:
            "With his knowledge of decisions during procurement, Charlie ensures that the finalized purchase order is aligned to the stakeholders’ needs.",
          background: "inspire11",
          filename: "nicole/buy5.png",
        }, */
        {
          id: 6,
          paragraph:
            "Charlie’s team also compiles digitized forms into a central location on Dell.com so that completing the purchasing agreement is as simple as possible.",
          background: "learn12",
          filename: "nicole/buy6.png",
          sectionClass: "nicole-section",
        },
        {
          id: 7,
          paragraph:
            "Charlie’s team also compiles digitized forms into a central location on Dell.com so that completing the purchasing agreement is as simple as possible.",
          background: "blueleftlight",
          filename: "nicole/buy7.png",
          sectionClass: "nicole-section",
        },
        {
        id: 9,
          paragraph:"After reviewing everything, Art selects one of the personalized server packages from the assessment results page and adds in an accessibility service as well.",
          background: "art-inspire04",
          filename:"art/art-buy-1.png",
          sectionTitle: "art",
          sectionClass: "art-section",
        },
        {
          id: 10,
          paragraph:"Once Art completes his purchase, he also receives a message through his authenticated Dell account with helpful resources regarding the products and services he bought.",
          background: "art-inspire04",
          filename:"art/art-buy-2.png",
          sectionClass: "art-section",
        },
/*         {
         id: 12,
          paragraph:"As she is checking out, Anna is given the option to create a student account, which will give her a discount on her order.",
          background: "ana/ana-inspire15",
          sectionTitle: "anna",
          sectionClass: "anna-section",

        }, */
        {
          id: 13,
          paragraph:"As she is checking out, Anna is given the option to create a student account, which will give her a discount on her order.",
          background: "ana-inspire15",
          filename:"ana/Buy-Account.png",
          sectionClass: "anna-section",
        },
        {
         id: 14,
          paragraph:"She will also get access to Dell student resources tailored to her studies, including tutorials in C++.",
          background: "ana-inspire16",
          filename:"ana/Use-Resources.png",
          sectionClass: "anna-section",
        },
        {
        id:16,
          paragraph:"Anna creates her Dell student account and moves forward with her discounted purchase.",
          background: "ana-inspire18",
          filename:"ana/Buy-Notification.png",
          sectionClass: "anna-section",
        },
      ],
    };
  },
};
</script>
