<template>
  <section class="section" :id="sectionTitle" :class="sectionClass">
    <div class="container" :class="background">
      <div class="video in-section hide" :class="videoClass" v-if="videoClass">
        <video
          id="v0"
          tabindex="0"
          autobuffer="autobuffer"
          preload="preload"
          autoplay
          muted
          loop
        >
          <source
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            src="../assets/medical.mp4"
          />
        </video>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="content">
            <p>{{ paragraph }}</p>
            <h1 class="title-section">{{ title }}</h1>
          </div>
        </div>
        <div class="col-4">
          <div class="content">
            <div id="myID" class="popup">
              <img :src="require(`@/assets/${filename}`)" v-if="filename" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Popup from "./popups/popup-template.vue";

export default {
  components: {
    Popup,
  },
  props: {
    msg: String,
    background: String,
    sectionTitle: String,
        sectionClass: String,
    paragraph: String,
    title: String,
    videoClass: String,
    filename: String,
  },
  mounted() {},
};
</script>

<style lang="scss">
.popup {
  img {
    width: 40vh;
  }
}
.section {
  z-index: 1;
  position: relative;
  height: 100vh;
  width: 100vw;
  opacity: 0;
}
.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 0;
}

.blueleftlight {
  background-image: url("@/assets/blue-bg-l.jpg");
}
.bluebottomlight {
  background-image: url("@/assets/blue-bg-lc.jpg");
  .popup img {
    width: 42vw;
  }
}
.bluetoplight {
  background-image: url("@/assets/blue-bg-uc.jpg");
  .popup img {
    width: 34vw;
  }
}
.buy01 {
  background-image: url("@/assets/hos_sc008_sh005.1001.jpg");
}
.nicole-video.video-done {
  /*     background-image: url("@/assets/scene-1-1.jpg") !important;
    video{
      display:none
    }  */
}
.inspire01 {
  background-image: url("@/assets/scene-1-1.jpg");
  /*   background: rgba(27, 65, 120, 0.2); */
}
.inspire02 {
  background-image: url("@/assets/scene-1-1.jpg");
  img: {
  }
}
.inspire02-1 {
  background-image: url("@/assets/nicole-secion-3.jpg");
  img {
    width: 45vw;
  }
}
.inspire03 {
  background-image: url("@/assets/nicole-secion-5.jpg");
}
.inspire05 {
  background: url("@/assets/hos_sc003_sh005.1001.jpg");
}
.inspire06 {
  background: url("@/assets/nicole-secion-5.jpg");
}
.inspire07 {
  background: url("@/assets/hos_sc002_sh010.1001.jpg");
}
.inspire08 {
  background: url("@/assets/hos_sc002_sh010.1001.jpg");
  .popup img {
    width: 32vw;
  }
}
.inspire09 {
  background-image: url("@/assets/hos_sc002_sh015.1001.jpg");
}
.inspire10 {
  background-image: url("@/assets/blue-bg-ur.jpg");
  img {
    width: 35vw;
  }
}
.inspire10-1 {
  background-image: url("@/assets/nicole-secion-10-1.jpg");
  img {
    width: 45vw;
  }
}
.inspire10-2 {
  background-image: url("@/assets/blue-bg-ur.jpg");
  .popup img {
    width: 40vw;
  }
}
.inspire11 {
  background-image: url("@/assets/blue-bg-lr.jpg");
  img {
    width: 38vw;
  }
}
.inspire12 {
  background-image: url("@/assets/blue-bg-ll.jpg");
  .popup img {
    width: 34vw;
  }
}
.learn01 {
  background-image: url("@/assets/hos_sc003_sh005.1001.jpg");
}
.learn02 {
  background-image: url("@/assets/blue-bg-lc.jpg");
  .popup img {
    width: 40vw;
  }
}
.learn11 {
  background-image: url("@/assets/hos_sc004_sh005.1001.jpg");
}
.learn12 {
  background-image: url("@/assets/blue-bg-ur.jpg");
}
.learn13 {
  background-image: url("@/assets/hos_sc004_sh010.1001.jpg");
  .popup {
    top: 37% !important;
    right: 20% !important;
    /* height: 4vh !important;*/
  }
  .popup img {
    width: 15vw !important;
  }
}
.summary {
  background-image: url("@/assets/home/intro-1.jpg");
}
.bottomMenu-show {
  position: fixed;
  width: 300px;
  height: 51px;
  z-index: 1;
  transition: 0.5s;
  box-shadow: 0 4px 10px rgb(0 0 0 / 8%);
  background: #003862;
  color: white;
  top: 98px;
  right: 205px;
  padding: 40px;
}
.message-text {
  position: relative;
  text-align: left;
  bottom: 150px;
  font-family: Roboto;
  font-size: 22px;
  width: 315px;
}
.userMessage {
  position: fixed;
  width: 300px;
  height: 100px;
  z-index: 1;
  box-shadow: 0 4px 10px rgb(0 0 0 / 8%);
  background: #0970c8;
  color: white;
  top: 294px;
  right: 106px;
  padding: 39px;
}

.userMessage-text {
  position: relative;
  text-align: left;
  bottom: 150px;
  font-family: Roboto;
  font-size: 22px;
  padding: 10px;
}

.secondDellMenu {
  position: fixed;
  width: 300px;
  height: 51px;
  z-index: 1;
  transition: 3s;
  box-shadow: 0 4px 10px rgb(0 0 0 / 8%);
  background: #003862;
  color: white;
  bottom: 179px;
  right: 205px;
  padding: 40px;
}

.content-show {
  top: 0;
  opacity: 1;
}
.content-above {
  top: 0;
}

.content-show .container {
  position: fixed;
  height: 100vh;
}
.content-show .popup div {
  &::nth-child(1) {
    animation: fadeIn 2.1s;
  }
  &::nth-child(1) {
    animation: fadeIn 2.1s;
  }
  &::nth-child(1) {
    animation: fadeIn 2.1s;
  }
}

.content p {
  position: sticky;
  position: -webkit-sticky;
  /* top: 33.3%; */
  font-family: Roboto;
  font-size: 32px;
  text-align: left;
  color: white;

  padding: 45px;
  margin: 60px;
  /* background-image: url("@/assets/Left-text-gradient.png"); */
}

h1 {
  font-size: 3em;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 0;
}
.indent-1 {
  float: left;
}
.indent-1-show {
  float: left;
  top: 0;
  opacity: 1;
  animation: fadeIn 2s;
}
.indent-1 section {
  width: 50%;
  float: left;
}
.hide {
  opacity: 0;
    display: none;
}
.show {
  opacity: 1;
}
p {
  font-size: 1.5em;
}

.entered {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

#portal {
  /* background-image: url('/men_sc001_sh005.1001.png'); */
  height: 100vh;
  width: 100vw;
  bottom: 60px;
  right: 60px;
}
#topLeft {
  position: relative;
}
#topLeft img {
  top: 5%;
  left: 3%;
  position: fixed;
  z-index: 2;
}
#topRight {
  position: relative;
}
#topRight img {
  top: 5%;
  right: 5%;
  position: fixed;
  z-index: 2;
}

/* div:nth-child(1) {
  .content-show .show & {
    animation: fadeInPop 2.5s;
  }
}
div:nth-child(2) {
  .content-show .show & {
    animation: fadeInPop 3.5s;
  }
}
div:nth-child(3) {
  .content-show .show & {
    animation: fadeInPop 4.6s;
  }
}
div:nth-child(4) {
  .content-show .show & {
    animation: fadeInPop 5.6s;
  }
}
div:nth-child(5) {
  .content-show .show & {
    animation: fadeInPop 9.6s;
  }
}
div:nth-child(6) {
  .content-show .show & {
    animation: fadeInPop 6.6s;
  }
}
div:nth-child(7) {
  .content-show .show & {
    animation: fadeInPop 7.6s;
  }
}
div:nth-child(8) {
  .content-show .show & {
    animation: fadeInPop 8.6s;
  }
}
div:nth-child(9) {
  .content-show .show & {
    animation: fadeInPop 9.6s;
  }
}

div:nth-child(10) {
  .content-show .show & {
    animation: fadeInPop 14.6s;
  }
}
div:nth-child(11) {
  .content-show .show & {
    animation: fadeInPop 15.6s;
  }
}
div:nth-child(12) {
  .content-show .show & {
    animation: fadeInPop 16.6s;
  }
} */

/* ----------------------------------------------
 * Generated by Animista on 2018-7-4 16:24:12
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeInPop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
